<template>
  {{response.message}}
</template>

<script>
  import Api from '@/services/Request';
  
  export default 
  {
    data: () => (
    {
      login: 
      {
        email: "token@gmail.com",
        password: "123456",
      },
      response: 
      {
        message: "",
        status: ""
      },
    }),
    methods: 
    {
      LoginToken() 
      {  
        Api.post("/login",this.login).then((result) => 
        {
          this.response.message =  result.data.msg
          this.response.status = result.data.status

          if(this.response.status == "true")
          {
            this.response.message = "Successful Login. Redirecting."
            // var sessionStore = JSON.stringify({ names: result.data.user.names, picture: result.data.user.picture})
            //localStorage.setItem('session', sessionStore)
            localStorage.setItem('names', result.data.user.names)
            localStorage.setItem('picture', result.data.user.picture)

            this.$store.commit("setAuthentication", true)
            this.$store.commit("setTypeUser", result.data.user.type)
            this.$store.commit("setModules", result.data.user.modules)

            if(result.data.user.type=='0')
            {
              this.$router.push({ name: "Team-Dashboard" });
              //setTimeout( () => this.$router.push({ path: '/dashboard'}), 3000);´
            }
          
            if(result.data.user.type=='1')
            {
              this.$router.push({ name: "Team-Dashboard" });
              //setTimeout( () => this.$router.push({ path: '/dashboard'}), 3000);´
            }

            if(result.data.user.type=='2')
            {
              //window.open('http://localhost:8080?code='+store.state.Token+'', '_blank');
              var redirect = localStorage.getItem('redirect');
              
              if(redirect != 0)
              {
                this.$router.push({ name: "Worker-"+redirect+"" });
              }
              // this.$router.push({ name: "Worker-Dashboard" });
              //setTimeout( () => this.$router.push({ path: '/dashboard'}), 3000);´
            }

            if(result.data.user.type=='3')
            {
              this.$router.push({ name: "Manager-Dashboard" });
              //setTimeout( () => this.$router.push({ path: '/dashboard'}), 3000);´
            }
          }
        })
      }
    },
    mounted()
    {
      this.LoginToken()
    }
  }
</script>
