<template>
     <LoginToken/>
</template>

<script>
// @ is an alias to /src
import LoginToken from '@/components/login/LoginToken.vue'

export default 
{

  components: 
  {
    LoginToken
  }
}
</script>
